/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-16 11:09:43
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-16 16:47:42
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listCategoryRebateRatio = params => axios({
    url: '/api/product/rebate/categoryRebateRatio/listRebate',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addCategoryRebateRatio = params => axios({
    url: '/api/product/rebate/categoryRebateRatio/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editCategoryRebateRatio = params => axios({
    url: '/api/category_rebate_ratio/system/categoryRebateRatio/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delCategoryRebateRatio = params => axios({
    url:'/api/category_rebate_ratio/system/categoryRebateRatio/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdCategoryRebateRatio = params => axios({
    url: '/api/product/rebate/categoryRebateRatio/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
