<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-16 11:09:43
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-16 16:53:49
-->
<template>
    <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" :width="1000" v-model="visible" @ok="toSubmit" okText="提交" :maskClosable="false">
        <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
            <a-form-model-item label="折扣比例" prop="numStr">
                <a-input v-model="rowData.numStr" placeholder="折扣比例"></a-input>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { editCategoryRebateRatio, selectByIdCategoryRebateRatio, addCategoryRebateRatio } from '../api/CategoryRebateRatioApi'
export default {
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            // 表单验证
            formRule: {
                num: [
                    { required: true, message: '请输入折扣比例', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            this.rowData = {}
            if(handle == 'edit') {
                selectByIdCategoryRebateRatio(row.id).then(res => {
                    this.rowData.categoryId = row.id
                    this.$set(this.rowData, 'numStr', res.body)
                })
            }
            
        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                const res = this.handle === 'add' ? await addCategoryRebateRatio(this.rowData) : await addCategoryRebateRatio(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        }
    },
    created() {

    },

}
</script>

<style lang="less" scoped>

</style>
