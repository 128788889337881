/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-16 11:09:43
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-16 16:54:36
 */
export const columns = [
{
    title: '分类名称',
    dataIndex: 'name',
    key: 'name',
    /*width:150,*/
    align:'left',
    ellipsis:true
},
{
    title: '分类图标',
    dataIndex: 'imgUrl',
    key: 'imgUrl',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'imgUrl'}
},
{
    title: '折扣比例',
    dataIndex: 'rebateRatio',
    key: 'rebateRatio',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '操作时间',
    dataIndex: 'modifyTime',
    key: 'modifyTime',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
]
